import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { UniPassConnector } from '@unipasswallet/web3-react-v6-connector';
import chains from '../config/chains';
import Web3 from 'web3';
import { DEFAULT_CHAIN_ID, DEFAULT_CHAIN_ID_TEST } from '../config/bizConfig';

export enum ConnectorNames {
    Injected = 'injected',
    WalletConnect = 'walletconnect',
    Particle = 'particle',
    Unipass = 'unipass',
    BitKeep = 'BitKeep',
    OpenBlock = 'openBlock',
}

export enum ParticleConnectorNames {
    Email = 'email',
    FaceBook = 'facebook',
    Google = 'google',
    Twitter = 'twitter',
    Github = 'github',
    Apple = 'apple',
}

export const supportedChainIds = chains.all.map((chain) => chain.id);

const injected = new InjectedConnector({
    supportedChainIds: supportedChainIds,
});

const bitkeep = new InjectedConnector({
    supportedChainIds: supportedChainIds,
});

const particle = new InjectedConnector({
    supportedChainIds: supportedChainIds,
});

const openBlock = new InjectedConnector({
    supportedChainIds: supportedChainIds,
});

export const DEFAULTCHAINID = process.env.REACT_APP_ENV == 'production' ? DEFAULT_CHAIN_ID : DEFAULT_CHAIN_ID_TEST;
const unipass = new UniPassConnector({
    chainId: DEFAULTCHAINID,
    returnEmail: false,
    rpcUrls: {
        mainnet: 'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
        bscMainnet: 'https://bsc-dataseed.binance.org',
        polygon: 'https://polygon-rpc.com/',
    },
});

const walletconnect = new WalletConnectConnector({
    rpc: chains.all.reduce((acc, chain) => Object.assign(acc, { [chain.id]: chain.rpcUrl }), {}),
    qrcode: true,
    //pollingInterval: 12000,
});

const connectors = {
    [ConnectorNames.Injected]: injected,
    [ConnectorNames.WalletConnect]: walletconnect,
    [ConnectorNames.BitKeep]: bitkeep,
    [ConnectorNames.Particle]: particle,
    [ConnectorNames.OpenBlock]: openBlock,
    [ConnectorNames.Unipass]: unipass,
};

export const connectorsArray = [
    {
        name: ConnectorNames.Injected,
        displayName: 'Metamask',
        connector: injected,
        url: 'metamask.png',
    },

    {
        name: ConnectorNames.WalletConnect,
        displayName: 'Wallet Connect',
        connector: walletconnect,
        url: 'walletConnectIcon.svg',
    },
    // currently bitkeep use window.ethereum, which conflicts with Metamask

    // {
    //     name: ConnectorNames.OpenBlock,
    //     displayName: 'OpenBlock',
    //     connector: openBlock,
    //     url: 'openblock.png',
    // },
    // {
    //     name: ConnectorNames.Unipass,
    //     displayName: 'Unipass',
    //     connector: unipass,
    //     url: 'unipass.svg',
    // },
];

export const particleArray = [
    {
        name: ParticleConnectorNames.Email,
        displayName: 'Email',
        connector: particle,
        url: 'email_icon.png',
    },
    {
        name: ParticleConnectorNames.Google,
        displayName: 'Google',
        connector: particle,
        url: 'google_icon.png',
    },
    {
        name: ParticleConnectorNames.Apple,
        displayName: 'Apple',
        connector: particle,
        url: 'apple_icon.png',
    },
    {
        name: ParticleConnectorNames.FaceBook,
        displayName: 'FaceBook',
        connector: particle,
        url: 'facebook_icon.png',
    },
    {
        name: ParticleConnectorNames.Github,
        displayName: 'Github',
        connector: particle,
        url: 'github_icon.png',
    },
    {
        name: ParticleConnectorNames.Twitter,
        displayName: 'Twitter',
        connector: particle,
        url: 'twitter_icon.png',
    },
];

export const getLibrary = (provider: any): Web3 => {
    return provider;
};

export default connectors;
