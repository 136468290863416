import { useWeb3WithDefault } from './useWeb3WithDefault';
import { chainLookupTable } from '../config/chains';

function useIsChainValid(path: string): boolean {
    const { chainId, account } = useWeb3WithDefault();
    if (!account) {
        return false;
    }
    return Object.keys(chainLookupTable[path]).includes(String(chainId));
}

export default useIsChainValid;
