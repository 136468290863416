import { Modal } from '../../../../../iZUMi-UI-toolkit/src/components/Modal/Modal';
import { BoxProps, useColorMode, HStack, VStack, Text, Image, Box } from '@chakra-ui/react';
import { TokenSymbol } from '../../../../../types/mod';
import { getColorThemeSelector } from '../../../../../utils/funcs';
import { i_text_copy_bold, i_text_d } from '../../../../../style';
import CustomButton from '../../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import Card from '../../../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { ToastLink, useCustomToast } from '../../../../../iZUMi-UI-toolkit/src/components/Toast/Toast';
import tokenContracts from '../../../../../config/tokens';
import { BoostAmountInput } from '../../../components/BoostAmountInput';
import { ModalHead } from './ModalHead';
import { useSelector } from 'react-redux';
import { RootState, RootDispatch } from '../../../../../state/store';
import { useEffect, useState, useMemo, useCallback } from 'react';
import { useRematchDispatch } from '../../../../../hooks/useRematchDispatch';
import { useWeb3WithDefault } from '../../../../../hooks/useWeb3WithDefault';
import { amount2Decimal, decimal2Amount, formatNumber } from '../../../../../utils/tokenMath';
import { isNumeric } from '../../../../../utils/valid';
import BigNumber from 'bignumber.js';
import { PoolEntryState } from '../../../../../state/models/farm/iZiSwap/fixRange/types';
import useTokenEntity from '../../../../../state/models/hooks/useTokenEntity';
import { niZiForDesiredBoost } from '../../../../../state/models/farm/iZiSwap/oneSide/funcs';
import { useGasPrice } from '../../../../../hooks/useGasPrice';
import { ModalAprBlock } from '../../../components/ModalAprBlock';
import { MiningCallbacks } from '../../../../../state/models/hooks/farm/common/callbacks';
import { useIZiToken } from '../../../../../hooks/useiZiToken';
import { useMiningFixRangeiZiContract } from '../../../../../utils/contractHelpers';
import { useFixRangeiZiEntity, FixRangeStakeParams } from '../../../../../state/models/hooks/farm/useFixRangeiZiEntity';
import { useTranslation } from 'react-i18next';
import { getChain, getTxLink } from '../../../../../config/chains';
import { getSafeTokenPrice } from '../../../../../state/models/token/funcs';

type StakeModalProps = {
    isOpen: boolean | any;
    onClose: () => void;
    onSuccess?: () => void;
    entry: PoolEntryState;
    index: number;
} & BoxProps;

interface BoostData {
    niZiDecimal: number;
    boostAPR: number;
    boostRate: number;
}

export const StakeModal: React.FC<StakeModalProps> = (props) => {
    const { isOpen, onClose, entry, index, onSuccess, ...rest } = props;
    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { iZiToken } = useIZiToken();
    const { token, account: accountModel } = useSelector((state: RootState) => state);
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const { account, web3, chainId } = useWeb3WithDefault();

    const position = useMemo(() => {
        return [...(entry.liquidityList ?? []), ...(entry.stakedLiquidityList ?? [])][index];
    }, [entry, index]);

    const contract = useMiningFixRangeiZiContract(entry.meta.miningContract, entry.meta.contractVersion);

    const fixRangeEntity = useFixRangeiZiEntity(entry);

    console.log('data.apr: ', entry.data.apr);
    console.log('position list: ', [...(entry.liquidityList ?? []), ...(entry.stakedLiquidityList ?? [])]);
    console.log('position: ', position);

    const baseAPY = useMemo(() => {
        const originBaseAPY = Array.isArray(entry.data.apr) ? entry.data.apr[0] : 0;
        const ret = Number(
            new BigNumber(entry.data.totalVLiquidity)
                .times(originBaseAPY)
                .div(new BigNumber(entry.data.totalVLiquidity).plus(position.vLiquidity))
        );
        return ret;
    }, [entry, position]);

    const initialAPY = entry.data.totalNiZi === '0' ? baseAPY * 2.5 : baseAPY;

    const [boostData, setBoostData] = useState({ niZiDecimal: 0, boostRate: 0, boostAPR: initialAPY } as BoostData);

    const toast = useCustomToast();
    const { gasPrice } = useGasPrice();

    useEffect(() => {
        dispatch.account.fetchTokenBalanceIfMissing({
            account: account as unknown as any,
            web3,
            token: iZiToken,
            chainId,
        });
        dispatch.token.fetchTokenPriceIfMissing(iZiToken);
    }, [account, web3, chainId, dispatch]);

    const niZi = decimal2Amount(new BigNumber(boostData.niZiDecimal), iZiToken) ?? new BigNumber(0);

    const updateNiZiDecimal = (niZiDecimal: number) => {
        const totalNiZi = entry.data.totalNiZi;
        const totalVliquidity = entry.data.totalVLiquidity;
        const vLiquidity = position.vLiquidity;

        const niZi = decimal2Amount(new BigNumber(niZiDecimal), iZiToken) ?? new BigNumber(0);

        const positionNiZi = parseFloat(niZi.toString());

        const vliquidityShare = new BigNumber(vLiquidity).div(new BigNumber(totalVliquidity).plus(vLiquidity));

        const newTotalNiZi = new BigNumber(totalNiZi).plus(positionNiZi).toFixed(0);
        const iZiShare = newTotalNiZi === '0' ? new BigNumber(1) : niZi.div(newTotalNiZi);

        const boostAPR = Math.min(baseAPY * (1 + (1.5 * Number(iZiShare)) / Number(vliquidityShare)), baseAPY * 2.5);
        setBoostData({
            niZiDecimal,
            boostRate: 0,
            boostAPR,
        } as BoostData);
    };

    const changeBoostRate = (boostRate: number) => {
        const totalNiZi = entry.data.totalNiZi;
        const totalVliquidity = entry.data.totalVLiquidity;
        const positionVLiquidity = position.vLiquidity;

        let iZiNeeded = niZiForDesiredBoost(
            Number(positionVLiquidity),
            Number(totalVliquidity) + Number(positionVLiquidity),
            Number(totalNiZi),
            boostRate
        );
        iZiNeeded = iZiNeeded < 0 ? 0 : iZiNeeded;
        const neededNiZiDecimal = amount2Decimal(new BigNumber(iZiNeeded), iZiToken) ?? 0;

        setBoostData({
            niZiDecimal: neededNiZiDecimal,
            boostRate,
            boostAPR: baseAPY * boostRate,
        } as BoostData);
    };

    const iZiEntity = useTokenEntity(iZiToken, entry.meta.miningContract, undefined, true);

    const tokenIconBlock = (token: any) => (
        <VStack w="100%" justifyContent="space-between" pt="20px">
            <HStack>
                <Image borderRadius="9px" w="30px" h="30px" src={process.env.PUBLIC_URL + tokenContracts[token as TokenSymbol].icon} />
                <Text className={i_text_copy_bold} fontSize="32px" color={colorTheme('tertiary.800', 'tertiary.100')}>
                    {token}
                </Text>
            </HStack>
        </VStack>
    );

    const ApproveButton = (
        <CustomButton
            text={t('Approve') + ' ' + TokenSymbol.IZI}
            variant="purple"
            mb="2px"
            w="272px"
            h="50px"
            marginLeft="30px"
            onClick={() => {
                const chain = getChain(chainId);
                const toastLink = {} as ToastLink;
                iZiEntity
                    .handleApprove()
                    .on('transactionHash', (hash: string) => {
                        if (chain) {
                            toastLink.title = 'View on ' + chain.name;
                            toastLink.link = getTxLink(hash, chain);
                        }
                        toast('info', 'Approving iZi', undefined, toastLink);
                    })
                    .then(() => {
                        toast('success', 'Approve successfully', undefined, toastLink);
                        iZiEntity.handleApproveSuccess();
                    })
                    .catch((e: any) => {
                        console.info('error   :', e.message);
                    });
            }}
        />
    );

    const StakeButton = (
        <CustomButton
            variant="purple"
            text={t('Stake')}
            mb="2px"
            w="272px"
            h="50px"
            marginLeft="30px"
            onClick={() => {
                const chain = getChain(chainId);
                const toastLink = {} as ToastLink;
                fixRangeEntity.stake(
                    {
                        nftId: position.nftId,
                        niZi: niZi.toFixed(0),
                    } as FixRangeStakeParams,
                    {
                        onTransactionHash: (hash: string) => {
                            if (chain) {
                                toastLink.title = 'View on ' + chain.name;
                                toastLink.link = getTxLink(hash, chain);
                            }
                            toast('info', 'Staking ...', undefined, toastLink);
                        },
                        then: (r: any) => {
                            toast('success', 'Stake successfully', undefined, toastLink);
                            console.log(r);
                            onSuccess?.();
                            onClose();
                        },
                        catch: (e: any) => {
                            console.log(e);
                        },
                    } as MiningCallbacks,
                    gasPrice
                );
            }}
        />
    );

    return (
        <Modal isOpen={isOpen} onClose={onClose} w={{ base: '73%', lg: '744px' }} minH="350px" title="Stake with Boost" {...rest}>
            <ModalHead entry={entry} liquidity={position} dispatch={dispatch.farmFixRangeiZi} />

            <Card variant="deep" w="100%" h="335px" mt="20px">
                <VStack w="100%" h="180px" p="20px 30px 20px 30px">
                    <HStack w="100%" justifyContent="space-between" alignItems="center">
                        <Box w="40%">{tokenIconBlock(TokenSymbol.IZI)}</Box>

                        <VStack w="60%" spacing="10px" alignItems="flex-start">
                            <HStack w="100%" justifyContent="space-between" px="10px">
                                <Text className={i_text_copy_bold}>Amount</Text>
                                <HStack>
                                    <Text className={i_text_d} color="tertiary.400" fontSize="12px !important">
                                        Balance:{' '}
                                    </Text>
                                    <Text className={i_text_d} fontSize="12px !important" color="tertiary.400">
                                        {accountModel.tokenBalance[TokenSymbol.IZI]
                                            ? formatNumber(accountModel.tokenBalance[TokenSymbol.IZI]?.[0], 2, 4)
                                            : '0.00'}
                                    </Text>
                                </HStack>
                            </HStack>
                            <Card w="100%" h="80px" px="10px">
                                <BoostAmountInput
                                    h="60px"
                                    errorInfo=""
                                    w="50%"
                                    inputValue={boostData.niZiDecimal}
                                    setBoostRate={changeBoostRate}
                                    maxRequire={100}
                                    price={getSafeTokenPrice(token, TokenSymbol.IZI)}
                                    token={TokenSymbol.IZI}
                                    handleSetValue={(v) => {
                                        isNumeric(v) && updateNiZiDecimal(v);
                                    }}
                                />
                            </Card>
                        </VStack>
                    </HStack>
                </VStack>

                <ModalAprBlock baseAPR={baseAPY} beforeBoostAPR={initialAPY} afterBoostAPR={boostData.boostAPR} />
            </Card>

            <VStack alignItems="center" mt="20px">
                {iZiEntity.isEnoughApproved(niZi.toFixed(0)) || Number(niZi.toFixed(0)) === 0 ? StakeButton : ApproveButton}
            </VStack>
        </Modal>
    );
};
