import { BoxProps, HStack, Text, Image, useColorMode, Stack } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WARNING_INFO } from '../../config/bizConfig';
import { useWeb3WithDefault } from '../../hooks/useWeb3WithDefault';
import { i_text_copy_bold } from '../../style';
import { getColorThemeSelector } from '../../utils/funcs';
export const WarningInfo: React.FC<BoxProps> = (props) => {
    const { ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { t } = useTranslation();
    const { chainId } = useWeb3WithDefault();
    const [showWarning, setShowWarning] = useState(false);

    const defaultInfo = useMemo(() => {
        return WARNING_INFO[chainId];
    }, [chainId]);

    useEffect(() => {
        if (defaultInfo) {
            setShowWarning(true);
        } else {
            setShowWarning(false);
        }
    }, [defaultInfo]);

    return showWarning ? (
        <HStack
            w="100%"
            bg={colorTheme(
                'linear-gradient(90deg, #37BE93 1.26%, #AEDCB8 101.26%)',
                'linear-gradient(90deg, #299071 1.26%, #144B43 101.26%);'
            )}
            borderRadius="5px"
            px={{ base: '10px', sm: '17px' }}
            py="6px"
            {...rest}
        >
            <Image boxSize="12px" src="/assets/warning/info.svg" fallbackSrc="/assets/warning/info.svg"></Image>
            <Text
                className={i_text_copy_bold}
                fontFamily={{ base: 'Montserrat-SemiBold', sm: 'Montserrat-Bold' }}
                lineHeight="14px"
                letterSpacing="0.02em"
                color={'#FFFFFF'}
            >
                {t(defaultInfo, { nsSeparator: false })}
            </Text>
            <Image
                boxSize="12px"
                src="/assets/warning/close.svg"
                fallbackSrc="/assets/warning/close.svg"
                ml="auto !important"
                cursor="pointer"
                onClick={() => {
                    setShowWarning(false);
                }}
            ></Image>
        </HStack>
    ) : (
        <></>
    );
};
