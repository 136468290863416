import { ChevronDownIcon, SearchIcon } from '@chakra-ui/icons';
import {
    Button,
    FormControl,
    FormLabel,
    HStack,
    Input,
    InputGroup,
    InputRightElement,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Stack,
    Switch,
    Box,
    useColorMode,
    Flex,
    InputLeftElement,
} from '@chakra-ui/react';
import { i_text_copy, i_text_copy_bold, i_text_d } from '../../../style';
import { useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import { getColorThemeSelector } from '../../../utils/funcs';
import { Filter } from '../Gallery/components/Filter';
import useIsMobile from '../../../hooks/useIsMobile';
import NetworkSelect from '../../../components/Select/NetworkSelect/NetworkSelect';
import CustomButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { useTranslation } from 'react-i18next';
import useIsPC from '../../../hooks/useIsPC';

type ControlsProps = {
    farm: any;
    dispatch: any;
};

const Controls: React.FC<ControlsProps> = (props) => {
    const { farm, dispatch } = props;
    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { account } = useWeb3React();
    const isMobile = useIsMobile();
    const isPC = useIsPC();
    const onOnlyStakedChange = useCallback(
        (e: any) => {
            const farmControl = { ...farm.farmControl };
            farmControl.stakedOnly = e.target.checked;
            dispatch.setFarmControl(farmControl);
        },
        [farm, dispatch]
    );

    const onSortByChange = useCallback(
        (e: any) => {
            const farmControl = { ...farm.farmControl };
            farmControl.sortBy = e;
            dispatch.setFarmControl(farmControl);
        },
        [farm, dispatch]
    );

    const onSearchKeyChange = useCallback(
        (e: any) => {
            console.log(e.target.value);
            const farmControl = { ...farm.farmControl };
            farmControl.searchKey = e.target.value;
            dispatch.setFarmControl(farmControl);
        },
        [farm, dispatch]
    );

    const onTypeChange = useCallback(
        (e: any) => {
            console.log(e);
            const farmControl = { ...farm.farmControl };
            farmControl.type = e;
            dispatch.setFarmControl(farmControl);
        },
        [farm, dispatch]
    );
    const LiveEndBlock = () => (
        <Stack
            w={{ base: '100%', xl: 'unset' }}
            justifyContent={{ base: 'end', xl: 'unset' }}
            direction="row"
            flexGrow={{ base: 0, xl: 1 }}
            alignItems="center"
            ml={{ base: 'auto', sm: 'unset' }}
        >
            <CustomButton
                variant="none"
                text={t('Live')}
                pt="0px"
                pl={{ base: '0px', xxl: '40px' }}
                className={i_text_copy}
                fontSize="14px"
                fontWeight={farm.farmControl.type === 'live' ? '600' : '500'}
                selected={farm.farmControl.type === 'live'}
                color={farm.farmControl.type === 'live' ? colorTheme('#172751', '#FFFFFF') : '#93B6BD'}
                onClick={() => {
                    onTypeChange('live');
                }}
            />

            <Box h="50%" w="2px" bg={colorTheme('tertiary.200', 'tertiary.400')} />

            <CustomButton
                variant="none"
                ml="0 !important"
                pt="0px"
                pl="10px"
                text={t('Ended')}
                className={i_text_copy}
                fontSize="14px"
                selected={farm.farmControl.type === 'ended'}
                fontWeight={farm.farmControl.type === 'ended' ? '600' : '500'}
                color={farm.farmControl.type === 'ended' ? colorTheme('#172751', '#FFFFFF') : '#93B6BD'}
                onClick={() => {
                    onTypeChange('ended');
                }}
            />
        </Stack>
    );

    const DexBlock = () => (
        <FormControl display="flex" alignItems="center" w="unset" pl="40px">
            <FormLabel className={i_text_copy} fontSize="12px" fontWeight="500" pt="6px" pr="3px">
                {t('DEX')}
            </FormLabel>
            <Menu>
                {() => (
                    <>
                        <MenuButton
                            as={Button}
                            rightIcon={<ChevronDownIcon />}
                            className={i_text_d}
                            fontSize="13px"
                            w={{ base: '130px', sm: '178px' }}
                            textAlign="start"
                        >
                            {t('iZiSwap')}
                        </MenuButton>
                        <MenuList className={i_text_copy}>
                            <MenuItem
                                as={Button}
                                variant="light"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    borderRadius: '4px',
                                    overflow: 'hidden',
                                    fontSize: '12px',
                                }}
                            >
                                {t('iZiSwap')}
                            </MenuItem>
                        </MenuList>
                    </>
                )}
            </Menu>
        </FormControl>
    );

    return isMobile ? (
        <HStack
            w="100%"
            alignItems="start"
            pt="16px"
            pb="8px"
            justifyContent={{ base: 'space-between' }}
            spacing={{ base: '10px', xxl: '20px' }}
        >
            <InputGroup h="35px" flex="1">
                <InputLeftElement boxSize="20px" mt="5px" ml="10px">
                    <SearchIcon boxSize={5} />
                </InputLeftElement>
                <Input
                    variant="filled"
                    h="30px"
                    placeholder={t('Search Tokens or Pools')}
                    size="lg"
                    className={i_text_copy_bold}
                    fontSize="12px"
                    onChange={(e: any) => onSearchKeyChange(e)}
                    value={farm.farmControl.searchKey || ''}
                    bg={colorTheme('#FFFFFF', 'rgba(37, 32, 59, 0.5)')}
                    color="tertiary.300"
                    letterSpacing="0.02em"
                    fontFamily="Montserrat"
                />
            </InputGroup>

            <Filter
                h="100%"
                ml="17px !important"
                alignItems="center"
                filterTitle={t('Mining Filter')}
                filterElement={[
                    <LiveEndBlock key={1} />,
                    <DexBlock key={2} />,
                    <Flex ml="auto !important" key={4}>
                        <NetworkSelect />
                    </Flex>,
                ]}
            ></Filter>
        </HStack>
    ) : (
        <HStack w="100%" alignItems="center" py="24px" spacing={{ base: '10px', xxl: '20px' }}>
            <InputGroup w={{ base: '280px', sm: '310px' }} h="41px" mt="-4px">
                <Input
                    variant="filled"
                    placeholder={t('Search Tokens or Pools')}
                    size="lg"
                    className={i_text_copy_bold}
                    fontSize="12px"
                    onChange={(e: any) => onSearchKeyChange(e)}
                    value={farm.farmControl.searchKey || ''}
                />
                <InputRightElement h="100%" mt="4px">
                    <SearchIcon boxSize={5} />
                </InputRightElement>
            </InputGroup>
            {!isPC && (
                <Filter
                    h="100%"
                    ml="auto !important"
                    alignItems="center"
                    filterTitle={t('Mining Filter')}
                    filterElement={[<LiveEndBlock key={1} />, <DexBlock key={2} />]}
                ></Filter>
            )}
            {isPC && (
                <Stack direction={{ base: 'column-reverse', xl: 'row' }}>
                    <LiveEndBlock />
                    <DexBlock />
                </Stack>
            )}
        </HStack>
    );
};

export default Controls;
