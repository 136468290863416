import { useSelector } from 'react-redux';
import { Box, Heading, HStack, useColorMode } from '@chakra-ui/react';

import { links } from '../../../config/links';
import { RootState } from '../../../state/store';
import { formatNumber } from '../../../utils/tokenMath';
import { ChainId, TokenSymbol } from '../../../types/mod';
import { getColorThemeSelector } from '../../../utils/funcs';
import { useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';
import WalletButton from '../../../components/Buttons/WalletButton/WalletButton';
import HomeJumpButton from '../../../components/Buttons/HomeJumpButton/HomeJumpButton';
import BuyiZiButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/BuyiZiButton/BuyiZiButton';
import { getSafeTokenPrice } from '../../../state/models/token/funcs';

export const MobileHeader: React.FC = () => {
    const { token } = useSelector((state: RootState) => state);
    const { chainId } = useWeb3WithDefault();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    return (
        <>
            <HStack w="100%" h="67px" direction="row" px="17px">
                <HStack spacing="20px">
                    <HomeJumpButton />
                    <Heading level="3" fontSize={'20px'} fontFamily="Montserrat-Medium">
                        {'LP Farm'}
                    </Heading>
                </HStack>
                <HStack ml="auto !important" spacing="10px">
                    {/* <BuyiZiButton
                        expand={true}
                        tokenPrice={formatNumber(getSafeTokenPrice(token, TokenSymbol.IZI))}
                        onClick={() => {
                            window.open(links.iZiBuyLink[chainId] ?? links.iZiBuyLink[ChainId.EthereumMainnet]);
                        }}
                    /> */}
                    <WalletButton />
                </HStack>
            </HStack>
            <Box
                w="100%"
                h="1px"
                bg={colorTheme(
                    '#F5EAFB',
                    'linear-gradient(270deg, rgba(60, 38, 81, 0.57) 3.17%, #453351 47.6%, rgba(65, 33, 85, 0.57) 101.54%)'
                )}
            ></Box>
        </>
    );
};
