import { TokenInfoFormatted } from '../../../../hooks/useTokenListFormatted';
import { ChainId, FarmFixRangeiZiContractVersion, TokenSymbol } from '../../../../types/mod';
import { sortedToken } from '../../../../utils/tokenMath';

export interface MiningPoolMetaConfig {
    tokenA: TokenInfoFormatted;
    tokenB: TokenInfoFormatted;
    feeTier: FeeTier;
    miningContract?: any;
    swapPoolAddress: string;
    initialToggle?: boolean;

    veiZiBoost?: boolean;
    iZiBoost?: boolean;
    contractVersion?: FarmFixRangeiZiContractVersion;

    useTimestamp?: boolean;

    twoRewards?: boolean;
    noFee?: boolean;
    feeCharged?: number;
    isEnded?: boolean;
    additionalKey?: string;
    useOriginLiquidity?: boolean;
}

export const miningPoolConfigList: Partial<Record<ChainId, MiningPoolMetaConfig[]>> =
    process.env.REACT_APP_ENV === 'production'
        ? {
              [ChainId.EthereumMainnet]: [],
              [ChainId.Matic]: [],
              [ChainId.Mantle]: [
                  {
                      ...sortedToken(TokenSymbol.USDLR, TokenSymbol.USDC, ChainId.Mantle),
                      miningContract: '0x9f58193b717449d00c7dcaf5d9F6f5AF48a09894',
                      swapPoolAddress: '0x355aa50e022abb7d4f9972f0ae4906b542f9f672',
                      veiZiBoost: false,
                      contractVersion: FarmFixRangeiZiContractVersion.V1,
                      feeTier: 0.05,
                      twoRewards: true,
                      iZiBoost: true,
                      noFee: false,
                      feeCharged: 1,
                      isEnded: false,
                      useTimestamp: true,
                      useOriginLiquidity: true,
                  } as MiningPoolMetaConfig,
                  {
                      ...sortedToken(TokenSymbol.USDC, TokenSymbol.USDT, ChainId.Mantle),
                      miningContract: '0xBF2b951Ae6af066A03Dbfa52b1329704D923980c',
                      swapPoolAddress: '0xC865dd3421a6DD706688955fe727C802A98c1df9',
                      veiZiBoost: false,
                      contractVersion: FarmFixRangeiZiContractVersion.V1,
                      feeTier: 0.05,
                      twoRewards: true,
                      iZiBoost: true,
                      noFee: false,
                      feeCharged: 1,
                      isEnded: false,
                      useTimestamp: true,
                  } as MiningPoolMetaConfig,
              ],
              [ChainId.Arbitrum]: [
                  {
                      ...sortedToken(TokenSymbol.IUSD, TokenSymbol.slUSDT, ChainId.BSC),
                      miningContract: '0xa2337F50091dbfC8e802F811eaD575162FA104e6',
                      swapPoolAddress: '0x1f9cfbbf25dc7b47e9ef05963fc7b57107af3136',
                      veiZiBoost: false,
                      contractVersion: FarmFixRangeiZiContractVersion.V1,
                      feeTier: 0.04,
                      twoRewards: false,
                      iZiBoost: true,
                      noFee: false,
                      feeCharged: 1,
                      isEnded: false,
                  } as MiningPoolMetaConfig,
                  {
                      ...sortedToken(TokenSymbol.IUSD, TokenSymbol.slUSDC, ChainId.BSC),
                      miningContract: '0xc41806270E9f45752287C249e450F8cb3aD5F440',
                      swapPoolAddress: '0x3Ff2C3916E038dE6F2Cf0890916076456A8ccbD7',
                      veiZiBoost: false,
                      contractVersion: FarmFixRangeiZiContractVersion.V1,
                      feeTier: 0.04,
                      twoRewards: false,
                      iZiBoost: true,
                      noFee: false,
                      feeCharged: 1,
                      isEnded: false,
                  } as MiningPoolMetaConfig,
              ],
              [ChainId.BSC]: [
                  {
                      ...sortedToken(TokenSymbol.ankrBNB, TokenSymbol.BNB, ChainId.BSC),
                      miningContract: '0xF6A40dE06aD9f1ca0A83d73eF389CbCEf5ACc8D4',
                      swapPoolAddress: '0x0b393f7819ea3e47b43790c9c142a5e4fb62a24b',
                      veiZiBoost: false,
                      contractVersion: FarmFixRangeiZiContractVersion.V1,
                      feeTier: 0.04,
                      twoRewards: true,
                      iZiBoost: true,
                      noFee: false,
                      feeCharged: 1,
                      isEnded: true,
                  } as MiningPoolMetaConfig,
                  //   {
                  //       ...sortedToken(TokenSymbol.aBNBc, TokenSymbol.BNB, ChainId.BSC),
                  //       miningContract: '0x4b118acdEC0dab9A04481f97750f0afd8b05d7a8',
                  //       swapPoolAddress: '0xe70B807D3483738AAe24FD163C6a27E329367c08',
                  //       veiZiBoost: false,
                  //       contractVersion: FarmFixRangeiZiContractVersion.V1,
                  //       feeTier: 0.04,
                  //       twoRewards: true,
                  //       iZiBoost: true,
                  //       noFee: false,
                  //       feeCharged: 1,
                  //       isEnded: true,
                  //   } as MiningPoolMetaConfig,
                  {
                      ...sortedToken(TokenSymbol.BUSD, TokenSymbol.USDT, ChainId.BSC),
                      miningContract: '0x50e024bd504f066fd7f343d6d048f4bd97b1064e',
                      swapPoolAddress: '0x3b82208353012d154A3C00aaa331686DC09301B0',
                      veiZiBoost: false,
                      contractVersion: FarmFixRangeiZiContractVersion.V1,
                      feeTier: 0.04,
                      twoRewards: false,
                      iZiBoost: true,
                      noFee: false,
                      feeCharged: 1,
                      isEnded: true,
                  } as MiningPoolMetaConfig,
                  {
                      ...sortedToken(TokenSymbol.IUSD, TokenSymbol.USDT, ChainId.BSC),
                      miningContract: '0xAdA24c4d35252001DD0947570E85DE01650160a1',
                      swapPoolAddress: '0x6BCf92CFbDB740fc99c804c3f78B7F2911F10F8C',
                      veiZiBoost: false,
                      contractVersion: FarmFixRangeiZiContractVersion.V1,
                      feeTier: 0.04,
                      twoRewards: false,
                      iZiBoost: true,
                      noFee: false,
                      feeCharged: 1,
                      isEnded: true,
                  } as MiningPoolMetaConfig,
                  {
                      ...sortedToken(TokenSymbol.IUSD, TokenSymbol.slUSDT, ChainId.BSC),
                      miningContract: '0xAcE12A04d0fC98FCC316AF11dE409788305f572e',
                      swapPoolAddress: '0x414b8c2fc621c2727c96c2c364901e22fa68a70f',
                      veiZiBoost: false,
                      contractVersion: FarmFixRangeiZiContractVersion.V1,
                      feeTier: 0.04,
                      twoRewards: false,
                      iZiBoost: true,
                      noFee: false,
                      feeCharged: 1,
                      isEnded: false,
                  } as MiningPoolMetaConfig,
                  {
                      ...sortedToken(TokenSymbol.IUSD, TokenSymbol.slUSDC, ChainId.BSC),
                      miningContract: '0x7619bBC02864b93d481f4B9c5428B5Ad3087E225',
                      swapPoolAddress: '0xbc18096c15461e93b16c9cb93f2309019ed7f2d0',
                      veiZiBoost: false,
                      contractVersion: FarmFixRangeiZiContractVersion.V1,
                      feeTier: 0.04,
                      twoRewards: false,
                      iZiBoost: true,
                      noFee: false,
                      feeCharged: 1,
                      isEnded: false,
                  } as MiningPoolMetaConfig,
                  //   {
                  //       ...sortedToken(TokenSymbol.BNBX, TokenSymbol.BNB, ChainId.BSC),
                  //       miningContract: '0x0161D7Ac41F530aca89Fa2548A63eEd7D3a7E02a',
                  //       swapPoolAddress: '0x9d03c0ab200e970d805ef00567afad7390a75302',
                  //       veiZiBoost: false,
                  //       feeTier: 0.04,
                  //       twoRewards: true,
                  //       iZiBoost: true,
                  //       contractVersion: FarmFixRangeiZiContractVersion.V1,
                  //       noFee: false,
                  //       feeCharged: 1,
                  //       isEnded: true,
                  //   } as MiningPoolMetaConfig,
                  //   {
                  //       ...sortedToken(TokenSymbol.BNB, TokenSymbol.USDT, ChainId.BSC),
                  //       miningContract: '0x1B8c940bE8a5a9F1ABd9C24eA337978f44376aE7',
                  //       swapPoolAddress: '0xF964529721ECD0C9386d922a37CbBD2b67EA6E93',
                  //       veiZiBoost: false,
                  //       contractVersion: FarmFixRangeiZiContractVersion.V1,
                  //       feeTier: 0.2,
                  //       twoRewards: false,
                  //       iZiBoost: true,
                  //       noFee: false,
                  //       feeCharged: 1,
                  //       isEnded: true,
                  //   } as MiningPoolMetaConfig,
              ],
              [ChainId.ZkSyncEra]: [
                  //   {
                  //       ...sortedToken(TokenSymbol.USDC, TokenSymbol.USDT, ChainId.ZkSyncEra),
                  //       miningContract: '0x67A1c85F8e1BF1F7f31d1bc5d0A8e69ed92A9141',
                  //       swapPoolAddress: '0x667f19b8db045db612c1ecb371bcfef9ac3a401e',
                  //       veiZiBoost: false,
                  //       contractVersion: FarmFixRangeiZiContractVersion.V1,
                  //       feeTier: 0.04,
                  //       twoRewards: true,
                  //       iZiBoost: true,
                  //       noFee: false,
                  //       feeCharged: 0.5,
                  //       isEnded: false,
                  //       useTimestamp: true,
                  //   } as MiningPoolMetaConfig,
              ],
          }
        : {
              [ChainId.BSCTestnet]: [
                  {
                      ...sortedToken(TokenSymbol.USDT, TokenSymbol.USDC, ChainId.BSCTestnet),
                      miningContract: '0x354Fb69467FE4174b9e2f6f07Ec2591173047443',
                      swapPoolAddress: '0xcE6Ffb9ea973B84A15a04D4ffb254151157F2709',
                      veiZiBoost: false,
                      contractVersion: FarmFixRangeiZiContractVersion.V1,
                      useTimestamp: true,
                      feeTier: 0.04,
                      twoRewards: true,
                      iZiBoost: true,
                      noFee: false,
                      feeCharged: 1,
                      isEnded: false,
                  } as MiningPoolMetaConfig,
              ],
              [ChainId.AuroraTestnet]: [
                  {
                      ...sortedToken(TokenSymbol.USDT, TokenSymbol.USDC, ChainId.AuroraTestnet),
                      miningContract: '0x6769D7bF1565218b93F3E42338c21eeBDD46cC4e',
                      swapPoolAddress: '0xF5173690d06fA8C2bDD0F4e074d6300465BF5888',
                      veiZiBoost: false,
                      contractVersion: FarmFixRangeiZiContractVersion.V1,
                      feeTier: 0.01,
                      twoRewards: true,
                      iZiBoost: true,
                      noFee: false,
                      feeCharged: 1,
                      isEnded: false,
                  } as MiningPoolMetaConfig,
              ],
          };
