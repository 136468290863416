import React, { useState, useEffect } from 'react';
import {
    Accordion,
    AccordionButton,
    AccordionItem,
    AccordionPanel,
    AccordionIcon,
    Link,
    Box,
    useColorMode,
    Text,
    Image,
    HStack,
    useDisclosure,
    Collapse,
} from '@chakra-ui/react';
import { Link as ReactLink, useHistory, useLocation } from 'react-router-dom';
import NavButton from '../NavButton/NavButton';
import { useTranslation } from 'react-i18next';
import { getColorThemeSelector } from '../../../utils/funcs';
import { i_text_d } from '../../../style';

interface Props {
    page: PageConfig;
    showNav?: boolean;
    setShowNav?: (e: boolean) => void;
    img?: string;
}

const NavAccordion: React.FC<Props> = ({ page, showNav, setShowNav, img }) => {
    const location = useLocation();
    const currentPage = location.pathname.substring(1);
    const { t } = useTranslation();
    const [index, setIndex] = useState([-1]);
    const history = useHistory();

    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const color = colorTheme('#88CEEF', '#88CEEF');
    const colorHover = colorTheme('#29A9E9', '#64CCFF');
    const backgroundColor = colorTheme('#F5F7FF', '#88CEEF');
    const { isOpen, onToggle } = useDisclosure();

    useEffect(() => {
        if (!showNav) {
            setIndex([-1]);
        }
    }, [showNav, setIndex, currentPage, page]);

    useEffect(() => {
        if (showNav && currentPage.split('/')[0] === page.name) {
            setTimeout(() => {
                setIndex([0]);
            }, 0);
        }
    }, [showNav, setIndex, currentPage, page]);

    const onClick = (page: PageConfig) => {
        if (!showNav) {
            setTimeout(() => {
                setIndex([0]);
            }, 0);
        } else {
            setIndex([Number(!index[0])]);
        }
        if (index[0] != 0 && page.address) {
            history.push(page.address);
        }
        setShowNav && setShowNav(true);
    };

    return (
        <Accordion
            allowToggle={true}
            // defaultIndex={currentPage.split('/')[0] === page.name ? 0 : -1}
            index={index}
            motionPreset="none"
            transition="none"
        >
            <AccordionItem border="none" isFocusable={false} motion="false" transition="none" motionPreset="none">
                <AccordionButton
                    padding="0"
                    _hover={{ bgColor: 'unset' }}
                    isExpanded={isOpen}
                    onClick={() => {
                        onToggle();
                        onClick(page);
                    }}
                    transitionDuration="0s"
                    transitionProperty="none"
                    transform="null"
                    transition="none"
                >
                    <NavButton disableLink={true} page={page} isSelected={false} width="100%" showNav={showNav} img={img}>
                        <Image
                            boxSize="20px"
                            src={isOpen ? '/assets/sidebar/open.svg' : '/assets/sidebar/close.svg'}
                            fallbackSrc={isOpen ? '/assets/sidebar/open.svg' : '/assets/sidebar/close.svg'}
                        ></Image>
                    </NavButton>
                </AccordionButton>

                <AccordionPanel p="0 0 0 0px">
                    {showNav &&
                        page.children.map((child) =>
                            child.address?.startsWith('http') ? (
                                <Link
                                    key={child.name + child.address}
                                    href={child.address}
                                    rel="noreferrer"
                                    display="block"
                                    fontFamily="Montserrat-Medium"
                                    fontSize="13px"
                                    pt="13px"
                                    pb="11px"
                                    pl="58px"
                                    pr="34px"
                                    color={color}
                                    target="_blank"
                                    _hover={{
                                        color: colorHover,
                                    }}
                                >
                                    <HStack>
                                        <Image boxSize="20px" src={child.icon}></Image>
                                        <Text color="#80839f" className={i_text_d} fontSize="14px !important">
                                            {showNav ? t(child.name) : t(child.name).charAt(0)}
                                        </Text>
                                    </HStack>
                                </Link>
                            ) : (
                                <ReactLink to={child.address as string} key={child.name + child.address}>
                                    <Box
                                        display="block"
                                        fontSize="13px"
                                        fontFamily="Montserrat-Medium"
                                        pt="13px"
                                        pr="34px"
                                        pl="88px"
                                        pb="11px"
                                        color={'/' + currentPage === child.address ? colorHover : color}
                                        //fontWeight="bold"
                                        target="_blank"
                                        _hover={{
                                            color: colorHover,
                                        }}
                                        bg={'/' + currentPage === child.address ? colorTheme('#E9F8F7', '#324862') : ''}
                                    >
                                        {showNav ? t(child.name) : t(child.name).charAt(0)}
                                        {child.marker ? (
                                            <Text
                                                className={i_text_d}
                                                display="inline"
                                                //transform="rotate(-7deg)"
                                                fontSize="10px"
                                                color="red"
                                                mr="40px"
                                                mt="-6px"
                                                float="right"
                                            >
                                                {child.marker}
                                            </Text>
                                        ) : null}
                                    </Box>
                                </ReactLink>
                            )
                        )}
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    );
};

export default NavAccordion;
