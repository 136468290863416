import { TokenInfoFormatted } from '../../../../hooks/useTokenListFormatted';
import { ChainId, TokenSymbol, FarmDynamicRangeiZiContractVersion } from '../../../../types/mod';
import { sortedToken } from '../../../../utils/tokenMath';

export interface MiningPoolMetaConfig {
    tokenA: TokenInfoFormatted;
    tokenB: TokenInfoFormatted;
    feeTier: FeeTier;
    miningContract?: any;
    iZiSwapAddress: string;
    initialToggle?: boolean;

    iZiBoost?: boolean;
    veiZiBoost?: boolean;
    contractVersion?: FarmDynamicRangeiZiContractVersion;
    twoRewards?: boolean;
    noFee?: boolean;
    feeCharged?: number;

    priceRangeRatio?: number;

    // if priceRangeRatio is undefined, means
    // leftRangeRatio != rightRangeRatio
    // leftRangeRatio >= 1
    leftRangeRatio?: number;
    // rightRangeRatio >= 1
    rightRangeRatio?: number;
    isEnded?: boolean;

    useTimestamp?: boolean;

    additionalKey?: string;
}

export const miningPoolConfigList: Partial<Record<ChainId, MiningPoolMetaConfig[]>> =
    process.env.REACT_APP_ENV === 'production'
        ? {
              [ChainId.EthereumMainnet]: [],
              [ChainId.Matic]: [],
              [ChainId.BSC]: [],
              [ChainId.Manta]: [
                  {
                      ...sortedToken(TokenSymbol.STONE, TokenSymbol.WUSDM, ChainId.Manta),
                      miningContract: '0x2C9bFcA337bAc7cBdA3B80D1b2d10ed6482D7C0f',
                      iZiSwapAddress: '0x5fe8b6ed86703e66ea727cd06c44ac5a6df9076f',
                      feeTier: 0.3,
                      twoRewards: true,
                      iZiBoost: true,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: false,
                      feeCharged: 1,
                      priceRangeRatio: 10,
                      isEnded: false,
                      useTimestamp: true,
                  },
              ],
              [ChainId.Mantle]: [
                  {
                      ...sortedToken(TokenSymbol.USDY, TokenSymbol.USDC, ChainId.Mantle),
                      miningContract: '0xe317266a34B9C35285d4050ad784d5190467F489',
                      iZiSwapAddress: '0xd1311115175a9d3f14fa27db2cd6c7a60723eaab',
                      feeTier: 0.05,
                      twoRewards: true,
                      iZiBoost: true,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: false,
                      feeCharged: 1,
                      priceRangeRatio: 2,
                      isEnded: false,
                      useTimestamp: true,
                  },
                  {
                      ...sortedToken(TokenSymbol.CTT, TokenSymbol.USDT, ChainId.Mantle),
                      miningContract: '0x3c61742c5b8B8c25e8D57b9f8115050463faf690',
                      iZiSwapAddress: '0xf7c5008ff737899d4f97992726d7572efeab87ff',
                      feeTier: 1,
                      twoRewards: true,
                      iZiBoost: true,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: false,
                      feeCharged: 1,
                      priceRangeRatio: 4,
                      isEnded: false,
                      useTimestamp: true,
                  },
                  {
                      ...sortedToken(TokenSymbol.AXL, TokenSymbol.USDC, ChainId.Mantle),
                      miningContract: '0xC0f4838625430A9c1d2D6D4ae9e834Af1dBA1D1c',
                      iZiSwapAddress: '0x5b39F3b6d83354d2a6adde26e39dEb0b0774937b',
                      feeTier: 0.3,
                      twoRewards: true,
                      iZiBoost: true,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: false,
                      feeCharged: 1,
                      priceRangeRatio: 4,
                      isEnded: false,
                      useTimestamp: true,
                  },
                  {
                      ...sortedToken(TokenSymbol.LEND, TokenSymbol.USDC, ChainId.Mantle),
                      miningContract: '0x414e38CF17a1CCcAc33599D2D88DAf6dbb680b1F',
                      iZiSwapAddress: '0xfc27767d5a406e732bc15b9bf8ad2907a1488a8f',
                      feeTier: 0.3,
                      twoRewards: true,
                      iZiBoost: true,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: false,
                      feeCharged: 1,
                      priceRangeRatio: 4,
                      isEnded: false,
                      useTimestamp: true,
                  },
              ],
              [ChainId.Arbitrum]: [
                  {
                      ...sortedToken(TokenSymbol.slETH, TokenSymbol.IUSD, ChainId.Arbitrum),
                      miningContract: '0xBBC9Fd984699F8a89B53b35bddf77494433Df225',
                      iZiSwapAddress: '0xF40D6E497067Cd246778EEA045751252034257b3',
                      feeTier: 0.2,
                      twoRewards: false,
                      iZiBoost: true,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: false,
                      feeCharged: 1,
                      priceRangeRatio: 1.25,
                      isEnded: false,
                  },
                  {
                      ...sortedToken(TokenSymbol.slETH, TokenSymbol.slUSDT, ChainId.Arbitrum),
                      miningContract: '0x2df0675e0C60aFDB18cfdff1Eb752E8AC1f0F43C',
                      iZiSwapAddress: '0x6b155C372DCCB076d3Bd90466Ee5f88F2921145e',
                      feeTier: 0.2,
                      twoRewards: false,
                      iZiBoost: true,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: false,
                      feeCharged: 1,
                      priceRangeRatio: 1.25,
                      isEnded: false,
                  },
                  {
                      ...sortedToken(TokenSymbol.slETH, TokenSymbol.slUSDC, ChainId.Arbitrum),
                      miningContract: '0xfbc1557Ba3A923Fc32333b15E5C7d3d6535eE32B',
                      iZiSwapAddress: '0x5eed1f05Daf0aC107175830eD3255e12Cf532CE4',
                      feeTier: 0.2,
                      twoRewards: false,
                      iZiBoost: true,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: false,
                      feeCharged: 1,
                      priceRangeRatio: 1.25,
                      isEnded: false,
                  },
              ],
              [ChainId.ZkSyncEra]: [
                  {
                      ...sortedToken(TokenSymbol.ETH, TokenSymbol.USDC, ChainId.ZkSyncEra),
                      miningContract: '0xC6e980CC272767766592BcBb9763665983CDC4a0',
                      iZiSwapAddress: '0x43ff8a10b6678462265b00286796e88f03c8839a',
                      feeTier: 0.2,
                      twoRewards: true,
                      iZiBoost: true,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: true,
                      feeCharged: 0,
                      leftRangeRatio: 1.40056,
                      rightRangeRatio: 1.4,
                      isEnded: false,
                      useTimestamp: true,
                  },
                  {
                      ...sortedToken(TokenSymbol.BEL, TokenSymbol.USDC, ChainId.ZkSyncEra),
                      miningContract: '0x9FB6Ca27D20E569E5c8FeC359C9d33D468d2803C',
                      iZiSwapAddress: '0xe84a3835CD9434A94f1632cF623bcb53eBAAb80c',
                      feeTier: 1,
                      twoRewards: true,
                      iZiBoost: true,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: false,
                      feeCharged: 1,
                      leftRangeRatio: 2,
                      rightRangeRatio: 2,
                      isEnded: false,
                      useTimestamp: true,
                      additionalKey: '02',
                  },

                  //   {
                  //       ...sortedToken(TokenSymbol.BEL, TokenSymbol.USDC, ChainId.ZkSyncEra),
                  //       miningContract: '0x3bd7a1D8c760d8be1bC57A3205cbFfBaDFB74D94',
                  //       iZiSwapAddress: '0x0d95A4DCD1dAb385Ae78EE85d60Afc73657B20C0',
                  //       feeTier: 1,
                  //       twoRewards: true,
                  //       iZiBoost: true,
                  //       contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                  //       noFee: false,
                  //       feeCharged: 1,
                  //       leftRangeRatio: 2,
                  //       rightRangeRatio: 2,
                  //       isEnded: false,
                  //       useTimestamp: true,
                  //       additionalKey: '01',
                  //   },
                  {
                      ...sortedToken(TokenSymbol.DEXTF, TokenSymbol.USDC, ChainId.ZkSyncEra),
                      miningContract: '0x353a12B0D46618c513bF5313AB7DfFB01227C234',
                      iZiSwapAddress: '0xa9addc3bb68dd601cc9d9ba6b0e5e4651a7c476f',
                      feeTier: 0.2,
                      twoRewards: true,
                      iZiBoost: true,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: false,
                      feeCharged: 1,
                      priceRangeRatio: 4,
                      isEnded: true,
                      useTimestamp: true,
                  },
                  {
                      ...sortedToken(TokenSymbol.ZKSP, TokenSymbol.ETH, ChainId.ZkSyncEra),
                      miningContract: '0x28702B2b58cCb5927b53aF91E5dC63270273d4C3',
                      iZiSwapAddress: '0x3a83b68de90a660e1780df9c84d57de3fa832e66',
                      feeTier: 1,
                      twoRewards: true,
                      iZiBoost: true,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: false,
                      feeCharged: 1,
                      priceRangeRatio: 4,
                      isEnded: true,
                      useTimestamp: true,
                  },
                  {
                      ...sortedToken(TokenSymbol.RF, TokenSymbol.ETH, ChainId.ZkSyncEra),
                      miningContract: '0x0C4E0F1Ff3B200db601b5A27adBaD288e804A35B',
                      iZiSwapAddress: '0x0f149b3f475d0acbc2ab849b50a0e14593450997',
                      feeTier: 0.2,
                      twoRewards: true,
                      iZiBoost: true,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: false,
                      feeCharged: 1,
                      priceRangeRatio: 4,
                      isEnded: true,
                      useTimestamp: true,
                  },
                  {
                      ...sortedToken(TokenSymbol.OT, TokenSymbol.USDC, ChainId.ZkSyncEra),
                      miningContract: '0xcB27b19c27e316f2deE2f07123716F36368C9e97',
                      iZiSwapAddress: '0x3fc25ee9c05eeddecd04f347b8ed702500993ec3',
                      feeTier: 0.2,
                      twoRewards: true,
                      iZiBoost: true,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: false,
                      feeCharged: 1,
                      priceRangeRatio: 4,
                      isEnded: true,
                      useTimestamp: true,
                  },
                  {
                      ...sortedToken(TokenSymbol.SPACE, TokenSymbol.ETH, ChainId.ZkSyncEra),
                      miningContract: '0x78e1F126a1BAE67bBC05A92CF2Bc171C69ee250a',
                      iZiSwapAddress: '0x682a471baa1e77d447b4a670925eb7c9ad752324',
                      feeTier: 0.2,
                      twoRewards: true,
                      iZiBoost: true,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: false,
                      feeCharged: 1,
                      priceRangeRatio: 4,
                      isEnded: true,
                      useTimestamp: true,
                  },
              ],
          }
        : {
              [ChainId.Rinkeby]: [],
              [ChainId.BSCTestnet]: [
                  {
                      ...sortedToken(TokenSymbol.USDT, TokenSymbol.BNB, ChainId.BSCTestnet),
                      miningContract: '0x2D509cfbd4503fa3811371f727cFF91075cAddB1',
                      iZiSwapAddress: '0x7b25C7e505244464Fea500109eBa88B82b26954b',
                      feeTier: 0.2,
                      twoRewards: true,
                      iZiBoost: true,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: false,
                      feeCharged: 1,
                      priceRangeRatio: 4,
                  },
                  {
                      ...sortedToken(TokenSymbol.IZI, TokenSymbol.BNB, ChainId.BSCTestnet),
                      miningContract: '0x380AE49a952516579E171CD7F99B0130d0908309',
                      iZiSwapAddress: '0xeAC5db5c8AF54DfC361338083D05dB847708e7A5',
                      useTimestamp: true,
                      feeTier: 0.2,
                      twoRewards: true,
                      iZiBoost: true,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: false,
                      feeCharged: 0.4,
                      priceRangeRatio: 4,
                  },
                  {
                      ...sortedToken(TokenSymbol.FeeA, TokenSymbol.FeeB, ChainId.BSCTestnet),
                      miningContract: '0x5C9B644efB689A8D97dAd94D78C953b518cc6593',
                      iZiSwapAddress: '0x01e39eECb26b04ECac644C369C58040025D27727',
                      useTimestamp: false,
                      feeTier: 0.2,
                      twoRewards: true,
                      iZiBoost: false,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: false,
                      feeCharged: 1,
                      leftRangeRatio: 4,
                      rightRangeRatio: 4,
                  },
                  {
                      ...sortedToken(TokenSymbol.FeeA, TokenSymbol.IUSD, ChainId.BSCTestnet),
                      miningContract: '0xf3656b20355AA315AFE1407269DB2fbc27d959A2',
                      iZiSwapAddress: '0x6d6588F17a14F081f2398174262C89C97d48B5D9',
                      useTimestamp: false,
                      feeTier: 0.2,
                      twoRewards: true,
                      iZiBoost: false,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: false,
                      feeCharged: 0.4,
                      leftRangeRatio: 2,
                      rightRangeRatio: 2,
                  },
              ],
              [ChainId.AuroraTestnet]: [
                  {
                      ...sortedToken(TokenSymbol.IZI, TokenSymbol.ETH, ChainId.AuroraTestnet),
                      miningContract: '0x346255D509d1FCb923077EcC53341F76Ee8182c3',
                      iZiSwapAddress: '0x8F32F1d69E8dd996d4e5D0E9937B388cE67d9551',
                      useTimestamp: false,
                      feeTier: 0.2,
                      twoRewards: true,
                      iZiBoost: true,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: false,
                      feeCharged: 1,
                      leftRangeRatio: 2,
                      rightRangeRatio: 4,
                  },
              ],
          };
