import React, { useState } from 'react';
import { Flex, Box, useColorMode, Image, Stack, VStack, HStack, Text, useDisclosure } from '@chakra-ui/react';
import Navbar from '../Navbar/Navbar';
import MediaList from '../MediaList/MediaList';
import ToggleIcon from '../../../iZUMi-UI-toolkit/src/components/Sidebar/ToggleIcon/ToggleIcon';
import { getColorThemeSelector } from '../../../utils/funcs';
import { useHistory } from 'react-router-dom';
import useIsMobile from '../../../hooks/useIsMobile';
import './Sidebar.css';
import BuyiZiButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/BuyiZiButton/BuyiZiButton';
import { formatNumber } from '../../../utils/tokenMath';
import { useSelector } from 'react-redux';
import { RootDispatch, RootState } from '../../../state/store';
import { ChainId, TokenSymbol } from '../../../types/mod';
import { links } from '../../../config/links';
import { useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';

import LanguageSelect from '../LanguageSelect/LanguageSelect';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import { useHover } from '../../../hooks/useHover';
import { getSafeTokenPrice } from '../../../state/models/token/funcs';
import ColorModeSelect from '../ColorModeSelect/ColorModeSelect';
import CopyrightInfo from '../CopyrightInfo/CopyrightInfo';
import ConnectButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/ConnectButton/ConnectButton';
import { useConnectModal } from '../../../providers/ConnectProvider';
import Identicon from '../../Identicon';
import CustomButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { i_text_copy, i_text_copy_bold, i_text_d } from '../../../style';
import WalletModal from '../../Modal/WalletModal/WalletModal';

const Sidebar: React.FC = () => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    const bgColor = colorTheme('#ffffff', '#292343');
    const [showNav, setShowNav] = useState(true);
    const history = useHistory();
    const isMobile = useIsMobile();
    const { token } = useSelector((state: RootState) => state);
    const { account, chainId, chainName } = useWeb3WithDefault();
    const onCopyAddress = () => {
        if (account) {
            navigator.clipboard.writeText(account);
        }
    };

    const { isOpen, onOpen, onClose } = useDisclosure();
    const { onOpenModal: onConnect } = useConnectModal();

    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const { pageLayout } = useSelector((state: RootState) => state);
    const [ref, isHovering] = useHover<any>();
    function truncateString(str: string, maxLength: number): string {
        if (str.length <= maxLength) {
            return str;
        } else {
            return str.slice(0, maxLength) + '...';
        }
    }
    return (
        <Flex
            h="100%"
            w={
                pageLayout.inAnimation
                    ? '0px'
                    : {
                          base: showNav ? '190px' : '0px',
                          lg: showNav ? '190px' : '108px',
                      }
            }
            direction="column"
            boxShadow="0 0 15px 0 rgb(0 0 0 / 3%)"
            bgColor={bgColor}
            display="flex"
            visibility={{ base: showNav ? 'visible' : 'hidden', lg: 'visible' }}
            align="flex-start"
            flexShrink={0}
            transitionProperty="all"
            transitionDuration="0.3s"
            transitionTimingFunction="ease"
            transitionDelay={showNav ? '0s' : '0.2s'}
            position={{ base: 'absolute', lg: 'relative' }}
            zIndex={{ base: '8', lg: '8' }}
        >
            {/* <ToggleIcon
                top="97px"
                right="-18px"
                showNav={showNav}
                setShowNav={setShowNav}
                zIndex="8 !important"
                visibility={isMobile ? 'hidden' : 'visible'}
            /> */}
            <Box flexShrink={0} w="100%" height="81px" position="relative" mb="10px !important">
                <Image
                    src={process.env.PUBLIC_URL + '/assets/home/iziLogo/logo.svg'}
                    width="33px"
                    height="33px"
                    position="absolute"
                    left="41px"
                    top="29px"
                    cursor="pointer"
                    onClick={() => {
                        history.push('/farm/bella/fix');
                    }}
                />
                <Image
                    src={colorTheme(
                        process.env.PUBLIC_URL + '/assets/home/iziLogo/Darktext.svg',
                        process.env.PUBLIC_URL + '/assets/home/iziLogo/Whitetext.svg'
                    )}
                    width={showNav ? '80px' : '0px'}
                    height={showNav ? '22px' : '0px'}
                    position="absolute"
                    left="74px"
                    top="34px"
                    opacity={showNav ? 1 : 0}
                    cursor="pointer"
                    onClick={() => {
                        history.push('/farm/bella/fix');
                    }}
                    transitionProperty="opacity"
                    transitionDuration="0.2s"
                    transitionTimingFunction="ease"
                    transitionDelay={showNav ? '0.3s' : '0s'}
                    fallbackSrc={process.env.PUBLIC_URL + '/assets/home/iziLogo/Darktext.svg'}
                />
            </Box>
            <Stack w="100%" pl="29px" pb="25px">
                {account ? (
                    <HStack
                        w="150px"
                        h="37px"
                        borderRadius="19px"
                        bg="rgba(0, 178, 249, 0.07)"
                        border="1px solid #00b2f9"
                        justifyContent="center"
                        cursor="pointer"
                        onClick={onOpen}
                    >
                        <Identicon size={16} />
                        <Text className={i_text_copy} color="#80839f">
                            {truncateString(account, 12)}
                        </Text>
                    </HStack>
                ) : (
                    <CustomButton
                        variant="none"
                        onClick={onConnect}
                        text="Connect Wallet"
                        className={i_text_copy}
                        w="140px"
                        h="40px"
                        borderRadius="19px"
                        border="0.07rem solid #00b2f9"
                        bg="rgba(0, 178, 249, 0.07)"
                        color={'#80839f'}
                        letterSpacing="-0.02em"
                    ></CustomButton>
                )}
            </Stack>

            {/* <ConnectButton Identicon={Identicon} onClick={onConnect}></ConnectButton> */}
            <Navbar setShowNav={setShowNav} showNav={true} />
            <Box flex={1} />

            {showNav ? (
                <>
                    {/* <Flex w={'184px'} direction="row" align="center" mb={3} mx="auto"> */}
                    {/* <ColorModeSelect showNav={showNav} /> */}
                    {/* <LanguageSelect ml="2px" /> */}
                    {/* <Flex ref={ref} ml="2px">
                            <Image
                                cursor="pointer"
                                boxSize="28px"
                                src={colorTheme('/assets/header/changeHeaderLayout.svg', '/assets/header/darkchangeHeaderLayout.svg')}
                                fallbackSrc="/assets/header/changeHeaderLayout.svg"
                                //isHovering={isHovering}
                                onClick={() => {
                                    setShowNav(false);
                                    dispatch.pageLayout.setIsInAnimation(true);
                                    setTimeout(() => {
                                        dispatch.pageLayout.setPageLayout('header');
                                    }, 500);
                                    setTimeout(() => {
                                        dispatch.pageLayout.setIsInAnimation(false);
                                    }, 500);
                                }}
                            ></Image>
                        </Flex> */}

                    {/* <BuyiZiButton
                            expand={showNav}
                            tokenPrice={formatNumber(getSafeTokenPrice(token, TokenSymbol.IZI))}
                            onClick={() => {
                                window.open(links.iZiBuyLink[chainId] ?? links.iZiBuyLink[ChainId.EthereumMainnet]);
                            }}
                            ml="auto"
                        /> */}
                    {/* </Flex> */}

                    {/* <MediaList />
                    <CopyrightInfo /> */}
                </>
            ) : (
                <Flex direction="column" w="95%" align="center" mb="97px" mx="auto" px={4} justifyContent="center">
                    {/* <ColorModeSelect showNav={showNav} w="unset" /> */}
                    {/* <Box mt="21px"></Box>
                    {<LanguageSelect />} */}
                    {/* <BuyiZiButton
                        mt="21px"
                        expand={showNav}
                        tokenPrice={formatNumber(getSafeTokenPrice(token, TokenSymbol.IZI))}
                        onClick={() => {
                            window.open(links.iZiBuyLink[chainId] ?? links.iZiBuyLink[ChainId.EthereumMainnet]);
                        }}
                    /> */}
                </Flex>
            )}
            <WalletModal
                account={account!}
                chainId={chainId!}
                isOpen={isOpen}
                onClose={onClose}
                onCopyAddress={onCopyAddress}
                scanName={chainName}
            />
        </Flex>
    );
};

export default Sidebar;
