import React from 'react';
import { Flex } from '@chakra-ui/react';
import NavButton from '../NavButton/NavButton';
import NavAccordion from '../NavAccordion/NavAccordion';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { config } from '../../../config/sidebar/config';

interface Props {
    setShowNav?: (e: boolean) => void;
    showNav?: boolean;
}

const Navbar: React.FC<Props> = (props) => {
    const location = useLocation();
    const currentPage = location.pathname.substring(1);
    const showNav = props.showNav;
    const setShowNav = props.setShowNav;

    return (
        <Flex
            direction="column"
            w="100%"
            cursor="pointer"
            overflowY="auto"
            css={{
                '&::-webkit-scrollbar': {
                    display: 'none !important',
                },
            }}
        >
            {config.pages.map((page) => {
                const commonAttr = {
                    key: page.name,
                    img: page.img,
                    showNav: showNav,
                };
                if (page.children.length === 0) {
                    console.info(
                        'currentPage  ',
                        currentPage,
                        ' page.address  ',
                        page.name.toLocaleLowerCase(),
                        '  isselect  ',
                        currentPage.toLocaleLowerCase().includes(page.name.toLocaleLowerCase())
                    );
                    return <NavButton {...commonAttr} page={page} isSelected={true} />;
                } else {
                    return <NavAccordion {...commonAttr} page={page} setShowNav={setShowNav} />;
                }
            })}
        </Flex>
    );
};

export default observer(Navbar);
