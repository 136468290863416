import { useBreakpointValue } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { LayoutStateEnum } from '../state/models/pageLayout/pageLayout';
import { RootState } from '../state/store';

export const fixedHeaderRoute = '/trade/pro';

export function useIsFixedHeader(): boolean {
    const location = useLocation();
    const currentPath = location.pathname;
    if (currentPath.includes(fixedHeaderRoute)) {
        return true;
    } else {
        return false;
    }
}

function useLayoutType(): LayoutStateEnum {
    const isXXl = useBreakpointValue({ base: false, xl: true, xxl: true, '2xl': true });
    const { pageLayout } = useSelector((state: RootState) => state);
    const isFixedHeader = useIsFixedHeader();
    // if (isFixedHeader) {
    //     return LayoutStateEnum.header;
    // } else {
    //     if (pageLayout.currentLayout === LayoutStateEnum.sidebar || (pageLayout.currentLayout === LayoutStateEnum.init && isXXl)) {
    //         return LayoutStateEnum.sidebar;
    //     }
    //     return LayoutStateEnum.header;
    // }
    return LayoutStateEnum.sidebar;
}

export default useLayoutType;
