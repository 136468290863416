import React, { useContext } from 'react';
import { Image, useDisclosure, Text, VStack, HStack, useColorMode, Divider, Box, Flex } from '@chakra-ui/react';
import CustomButton from '../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { Modal } from '../iZUMi-UI-toolkit/src/components/Modal/Modal';
import { ConnectorNames, connectorsArray, particleArray, ParticleConnectorNames } from '../utils/connectors';
import useAuth from '../hooks/useAuth';
import { i_h3, i_text_copy, i_text_d } from '../style';
import { getColorThemeSelector } from '../utils/funcs';
import { loginWithParticle } from '../utils/particle';
import useIsMobile from '../hooks/useIsMobile';
import { useRematchDispatch } from '../hooks/useRematchDispatch';
import { RootDispatch } from '../state/store';

const ConnectContext = React.createContext<{ onOpenModal: () => void }>(undefined!);

export const useConnectModal = () => {
    return useContext(ConnectContext);
};

const ConnectProvider: React.FC = ({ children }) => {
    const { login } = useAuth();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const isMobile = useIsMobile();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const ua = navigator.userAgent;
    const isChrome = ua.indexOf('Chrome') > -1 && ua.indexOf('Safari') > -1 && ua.indexOf('Edge') == -1;
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));

    return (
        <ConnectContext.Provider value={{ onOpenModal: onOpen }}>
            <Modal isOpen={isOpen} onClose={onClose} title={'Connect Wallet'} w={{ base: '80%', lg: '640px' }} top="45%" zIndex="12">
                <VStack spacing="30px" mt={isMobile ? '20px' : 'unset'}>
                    {connectorsArray.map(({ name, displayName, url }, i) => {
                        if (name == ConnectorNames.Particle) {
                            return;
                        }
                        return (
                            <CustomButton
                                w="100%"
                                className={i_text_d}
                                fontSize="17px"
                                key={i}
                                variant="none"
                                radiusType="md"
                                color={colorTheme('tertiary.700', 'tertiary.100')}
                                onClick={() => {
                                    onClose();
                                    login(name);
                                    dispatch.loginConfig.setLoginConfig(name);
                                }}
                                text={
                                    <HStack w={isMobile ? '90%' : '100%'} justifyContent="space-between">
                                        <Text as="span" mr="auto">
                                            {displayName}
                                        </Text>
                                        <Image src={process.env.PUBLIC_URL + '/assets/wallet/' + url} boxSize="32px" borderRadius="5px" />
                                    </HStack>
                                }
                            />
                        );
                    })}
                </VStack>
            </Modal>
            {children}
        </ConnectContext.Provider>
    );
};

export default ConnectProvider;
