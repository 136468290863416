import React from 'react';
import { Flex, Text, Box, useColorMode, Image } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useHover } from '../../../hooks/useHover';
import './NavButton.css';
import { getColorThemeSelector } from '../../../utils/funcs';
import placeholder from '../../../assets/placeholder.png';

interface Props {
    page: PageConfig;
    isSelected: boolean;
    children?: React.ReactNode;
    width?: string;
    disableLink?: boolean;
    showNav?: boolean;
    img?: string;
}

const NavButton: React.FC<Props> = ({ page, img, width, isSelected: isSelectedInitial, children, disableLink, showNav }) => {
    const [ref, isHovering] = useHover<HTMLDivElement>();
    //const isSelected = isSelectedInitial || isHovering;
    const isSelected = isSelectedInitial;
    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    const textStyle = colorTheme(
        {
            color: '#80839f',
        },
        {
            color: '#80839f',
        }
    );

    const textStyleSelect = colorTheme(
        {
            //bgImg: 'linear-gradient(98.73deg, #1F0C4E -2.37%, #4F1FB4 89.44%)',
            //bgClip: 'text',
            color: '#80839f',
        },
        {
            color: '#FFFFFF',
        }
    );

    const textStyleHover = colorTheme(
        {
            color: '#6E608F',
        },
        {
            color: '#D6D0E5',
        }
    );

    const [endTextStyle] = (() => {
        if (isSelected) {
            return [textStyleSelect];
        } else if (isHovering) {
            return [textStyleHover];
        } else {
            return [textStyle];
        }
    })();

    const opacity = (() => {
        if (isSelected) {
            return 1;
        } else if (isHovering) {
            return 0.8;
        } else {
            return 0.5;
        }
    })();

    const imgUrl = colorTheme(
        process.env.PUBLIC_URL + `/assets/sidebar/light${img}`,
        process.env.PUBLIC_URL + `/assets/sidebar/dark${img}`
    );

    const button = (
        <Flex
            direction="row"
            align="center"
            minH="50px"
            pos="relative"
            boxSizing="border-box"
            pl="26px"
            ref={ref}
            width={width}
            //className="trans"
            position="relative"
            bg={isSelected ? '#e5f9f7' : ''}
        >
            {
                //Icon !== undefined ? <Icon isHovering={isSelected} /> : undefined}
            }
            <Image
                display="inline"
                ml="4px"
                w="20px"
                h="20px"
                mt="2px"
                // opacity={opacity}
                // transitionProperty="all"
                // transitionDuration="0.2s"
                // transitionDelay="0.3s"
                src={imgUrl}
                fallbackSrc={placeholder}
            ></Image>

            <Text
                ml="10px"
                flex={1}
                fontFamily="Montserrat-Medium"
                //fontWeight="bold"
                letterSpacing="-0.02em"
                fontSize="14px"
                textAlign="left"
                display="flex"
                flexDir="row"
                //className="trans"
                whiteSpace="nowrap"
                // transitionProperty="all"
                // transitionDuration="0.2s"
                // transitionTimingFunction="ease"
                // transitionDelay={showNav ? '0.3s' : '0s'}
                // bg="linear-gradient(90deg, #5CC9AC -2.48%, #4DB1F1 125.74%)"
                // bgClip="text"
                // fill="transparent"
                // className="nav-button-text"
                {...(showNav ? endTextStyle : { color: 'transparent' })}
                color={isSelected ? '#00b6eb' : '#80839f'}
            >
                {showNav ? t(page.name) : ''}
                <Box
                    as="span"
                    display="inline-block"
                    pos="absolute"
                    left={showNav ? '30px' : '8px'}
                    top={showNav ? '15px' : '12px'}
                    color={'#9F9F9F'}
                >
                    {children}
                </Box>
            </Text>
        </Flex>
    );
    const extralLinkButton = (link: string) => {
        return (
            <Flex
                direction="row"
                align="center"
                minH="50px"
                pos="relative"
                boxSizing="border-box"
                pl="26px"
                ref={ref}
                width={width}
                //className="trans"
                position="relative"
                onClick={() => {
                    if (page.address && page.address.startsWith('http')) {
                        window.open(page.address);
                    }
                }}
            >
                {
                    //Icon !== undefined ? <Icon isHovering={isSelected} /> : undefined}
                }
                <Image
                    display="inline"
                    ml="4px"
                    w="20px"
                    h="20px"
                    mt="1px"
                    // opacity={opacity}
                    transitionProperty="all"
                    transitionDuration="0.2s"
                    transitionDelay="0.3s"
                    src={imgUrl}
                    fallbackSrc={placeholder}
                ></Image>

                <Text
                    ml="10px"
                    flex={1}
                    fontFamily="Montserrat-Medium"
                    //fontWeight="bold"
                    letterSpacing="-0.02em"
                    fontSize="14px"
                    textAlign="left"
                    display="flex"
                    flexDir="row"
                    //className="trans"
                    whiteSpace="nowrap"
                    transitionProperty="all"
                    transitionDuration="0.2s"
                    transitionTimingFunction="ease"
                    transitionDelay={showNav ? '0.3s' : '0s'}
                    {...(showNav ? endTextStyle : { color: 'transparent' })}
                >
                    {t(page.name)}
                    <Box
                        as="span"
                        display="inline-block"
                        pos="absolute"
                        right={showNav ? '19px' : '8px'}
                        top={showNav ? '12px' : '12px'}
                        color="#9F9F9F"
                    >
                        {children}
                    </Box>
                </Text>
            </Flex>
        );
    };
    let endButton = button;

    if (!disableLink) {
        page.address?.startsWith('http')
            ? (endButton = extralLinkButton(page.address))
            : (endButton = <Link to={page.address!}>{button}</Link>);
    }

    return endButton;
};

export default React.memo(NavButton);
