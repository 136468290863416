//import { useWeb3React } from '@web3-react/core';
import Info from '../../../iZUMi-UI-toolkit/src/components/Info/Info';
import { formatNumber } from '../../../utils/tokenMath';
import { isNumeric } from '../../../utils/valid';
import { BoxProps } from '@chakra-ui/layout';
import { FARM_CONFIG } from '../../../config/bizConfig';
import { useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';
import { useTranslation } from 'react-i18next';
import useIsMobile from '../../../iZUMi-UI-toolkit/src/hooks/useIsMobile';

export type APRProps = {
    apr: number[] | string[] | null;
    variant?: 'base' | 'range' | 'known';
    meta?: any;
    type?: string;
} & BoxProps;

export const APR: React.FC<APRProps> = (props) => {
    const { apr, meta, variant, type, ...rest } = props;
    const { chainId } = useWeb3WithDefault();
    const { t } = useTranslation();
    const isMobile = useIsMobile();

    return (
        <Info
            //w={account ? '118px' : '165px'}
            w="165px"
            label={variant === 'range' || (variant === 'known' && apr && apr?.length > 1) ? t('APR') : t('APR')}
            tooltip={
                isMobile
                    ? ''
                    : variant === 'range'
                    ? t(
                          'APR consists of liquidity provision fees gained in iZiSwap and LP token staking rewards. The maximum APR can be reached with a maximum iZi boost of 2.5x.'
                      )
                    : t(
                          'APR consists of liquidity provision fees gained in iZiSwap and LP token staking rewards. The maximum APR can be reached with a maximum iZi boost of 2.5x.'
                      )
            }
            spacing="10px"
            value={
                variant === 'known' && Array.isArray(apr) && apr.length === 1
                    ? formatNumber(Number(apr[0])) + '%'
                    : variant === 'known' && Array.isArray(apr) && apr.length > 1
                    ? (apr[0] > 10000 ? '9,999+' : formatNumber(Number(apr[0]))) +
                      '~' +
                      (apr[1] > 10000 ? '9,999+' : formatNumber(Number(apr[1]))) +
                      '%'
                    : variant === 'range'
                    ? Array.isArray(apr)
                        ? isNumeric(apr[0]) && isNumeric(apr[1])
                            ? (apr[0] > 100 ? '9,999+' : formatNumber(Number(apr[0]) * 100 + (FARM_CONFIG.APR_FEE_GAINS[chainId] ?? 0))) +
                              '~' +
                              (apr[1] > 100 ? '9,999+' : formatNumber(Number(apr[1]) * 100 + (FARM_CONFIG.APR_FEE_GAINS[chainId] ?? 0))) +
                              '%'
                            : '...'
                        : '...'
                    : !isNumeric(apr)
                    ? '...'
                    : (apr?.[0] ?? 0) > 100
                    ? '10,000+ %'
                    : formatNumber((Number(apr?.[0]) * 100 + 5) as unknown as number) + '%'
            }
            labelColor={isMobile ? '#A398BD' : 'unset'}
            valueStyle={isMobile ? { fontFamily: 'Montserrat-Medium' } : {}}
            {...rest}
        />
    );
};
