import { links } from '../links';

export const config = {
    pages: [
        {
            name: 'Liquidity Mining',
            img: 'Mint.png',
            address: 'https://liquidity.bella.fi/',
            children: [],
        },
        {
            name: 'Flex Savings',
            img: '',
            address: '',
            children: [
                {
                    name: 'Pools',
                    address: 'https://fs.bella.fi/#/flex-savings',
                    icon: '/assets/sidebar/lightSavings.png',
                },
                {
                    name: 'Farm',
                    address: 'https://fs.bella.fi/#/farm',
                    icon: '/assets/sidebar/farm.png',
                },
            ],
        },
        {
            name: 'LP Farm',
            img: 'LPFarm.svg',
            address: '/farm/bella/fix',
            children: [],
        },

        {
            name: 'Locker',
            img: 'Locker.png',
            address: 'https://locker.bella.fi/',
            children: [],
        },
        {
            name: 'Governance',
            img: 'Governance.png',
            address: 'https://snapshot.page/#/bella',
            children: [],
        },
    ],
} as { pages: PageConfig[] };
