import React from 'react';
import { Image, Center, VStack, useColorMode, Text, BoxProps, Tooltip, HStack } from '@chakra-ui/react';
import { getColorThemeSelector } from '../../utils/funcs';
import { i_h3 } from '../../style';
import chains from '../../config/chains';
import useIsChainValid from '../../hooks/useIsChainValid';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import useIsMobile from '../../hooks/useIsMobile';

type Props = {
    app: 'all' | 'farmiZi' | 'farmUni' | 'trade' | 'veiZi';
    content: ReactElement;
} & BoxProps;

const ChainNotValidBlockWrapper: React.FC<Props> = (props) => {
    const { app, content, ...rest } = props;
    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const isChainValid = useIsChainValid(app);
    const isMobile = useIsMobile();

    return isChainValid ? (
        <>{content}</>
    ) : (
        <Center w="100%" h="400px" alignItems="center" {...rest}>
            <VStack spacing="40px" w="90%">
                <HStack spacing="20px">
                    {chains[app].map((e, i) => {
                        return (
                            <Tooltip key={i} label={e.name}>
                                <Image
                                    boxSize={isMobile ? '30px' : '40px'}
                                    src={process.env.PUBLIC_URL + e.icon}
                                    borderRadius={isMobile ? '15px' : '20px'}
                                />
                            </Tooltip>
                        );
                    })}
                </HStack>
                <Text
                    className={i_h3}
                    fontSize={{ base: '12px', md: '16px', xl: '22px' }}
                    color={colorTheme('tertiary.500', 'tertiary.200')}
                    textAlign="center"
                >
                    {t('The current blockchain is not supported, please switch to the above chains.')}
                </Text>
            </VStack>
        </Center>
    );
};

export default ChainNotValidBlockWrapper;
