import {
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    chakra,
    Divider,
    Flex,
    Grid,
    HStack,
    Spacer,
    Stack,
    useColorMode,
    VStack,
    Image,
    Text,
} from '@chakra-ui/react';
import Card from '../../../../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { CardTag } from '../../../../../../iZUMi-UI-toolkit/src/components/CardTag/CardTag';
import CardTagContainer from '../../../../../../iZUMi-UI-toolkit/src/components/CardTag/Container';
import { ToastLink, useCustomToast } from '../../../../../../iZUMi-UI-toolkit/src/components/Toast/Toast';
import { FeeRate } from '../../../../../Trade/components/FeeRate';
import { TVL } from '../../../../components/TVL';
import { Earned } from '../../../components/Earned';
import { RewardOrPriceRange } from '../RewardOrPriceRange';
import { Rewards } from '../../../../components/Rewards';
import { TokenIcons } from '../../../../../Trade/components/TokenIcons';
import { APR } from '../../../../components/APR';
import { PositionList } from './PositionList';
import ApprovalForAllButton from './ApprovalForAllButton';
import { i_text_copy, i_text_copy_bold } from '../../../../../../style';
import React from 'react';
import { FarmRefreshHandle } from '../PoolList';
import { useBreakpointValue } from '@chakra-ui/media-query';
import { collectRewards, estimateCollectRewardsGas } from '../../../../../../net/contractCall/farm/fixRange/collectReward';
import { useRematchDispatch } from '../../../../../../hooks/useRematchDispatch';
import { RootDispatch } from '../../../../../../state/store';
import { CustomTag } from '../../../../../components/Tag';
import { getColorThemeSelector } from '../../../../../../utils/funcs';
import BigNumber from 'bignumber.js';
import { useGasPrice } from '../../../../../../hooks/useGasPrice';
import { useWeb3WithDefault } from '../../../../../../hooks/useWeb3WithDefault';

import { useContext } from 'react';
import { createContext } from 'react';
import { PoolEntryState } from '../../../../../../state/models/farm/iZiSwap/fixRange/types';
import { ChainId } from '../../../../../../types/mod';
import { useMiningFixRangeiZiContract } from '../../../../../../utils/contractHelpers';
import { TokenInfoFormatted } from '../../../../../../hooks/useTokenListFormatted';
import useIsMobile from '../../../../../../hooks/useIsMobile';
import { useTranslation } from 'react-i18next';
import { getChain, getTxLink } from '../../../../../../config/chains';

const PoolEntryContext = createContext<PoolEntryState | null>(null);

export const PoolEntryProvider = PoolEntryContext.Provider;

export function usePoolEntry(): PoolEntryState {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return useContext(PoolEntryContext)!;
}

const PoolLiquidityEntry = chakra(({ refreshPosition, refreshPoolListDataAndPosition, ...rest }) => {
    const entry = usePoolEntry();
    const { account, chainId } = useWeb3WithDefault();
    const oneLineMode = useBreakpointValue({ base: false, xxl: true, '2xl': true })!;
    console.log('oneLineMode  ', oneLineMode);
    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    const toast = useCustomToast();
    const isMobile = useIsMobile();

    const is2XL = oneLineMode;
    const isLoggedIn = !!account;
    const shouldExpand = isLoggedIn || !is2XL;
    const isNot2XL = !is2XL;

    const isFarming = isLoggedIn && entry.stakedLiquidityList && entry.stakedLiquidityList.length > 0;
    const readyToFarm = isLoggedIn && entry.liquidityList && entry.liquidityList.length > 0;
    const hasUnstakedPositions = entry.liquidityList && entry.liquidityList.length > 0;
    const isNeedApprove = !entry.userData.isApprovedForAll && hasUnstakedPositions;

    const miningContract = useMiningFixRangeiZiContract(entry.meta.miningContract, entry.meta.contractVersion);

    const { gasPrice } = useGasPrice();

    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));

    const additionalInfo = isMobile ? (
        <Stack w="100%">
            <RewardOrPriceRange
                data={entry.data}
                meta={entry.meta}
                label={t('Reward Range')}
                handleToggle={() => {
                    dispatch.farmFixRangeiZi.togglePoolMetaInitialToggle(entry.meta.liquidityPoolKey);
                }}
                type="mobile"
            />

            {isLoggedIn && isFarming ? (
                <Earned
                    gridRow="span 2"
                    justifySelf="end"
                    border="1px solid #EBE6F0"
                    borderRadius="4px"
                    p="11px 18px 11px 18px"
                    my="12px !important"
                    entry={entry}
                    onHarvest={() => {
                        if (miningContract && account) {
                            estimateCollectRewardsGas(miningContract, account).then((gas: number) => {
                                const chain = getChain(chainId);
                                const toastLink = {} as ToastLink;
                                const gasLimit = new BigNumber(gas * 1.1).toFixed(0, 2);
                                collectRewards(chainId as ChainId, miningContract, account, gasLimit, gasPrice)
                                    .on('transactionHash', (hash: string) => {
                                        if (chain) {
                                            toastLink.title = 'View on ' + chain.name;
                                            toastLink.link = getTxLink(hash, chain);
                                        }
                                        toast('info', 'Collecting all rewards ...', undefined, toastLink);
                                    })
                                    .then(() => {
                                        toast('success', 'Collect all rewards successfully.', undefined, toastLink);
                                        console.log('collectRewards success');
                                        refreshPosition?.();
                                    });
                            });
                        }
                    }}
                    earned={
                        entry.userData.earned &&
                        entry.userData.earned.map((item) => {
                            return parseFloat(item);
                        })
                    }
                    buttonProps={{ bg: colorTheme('#E2EAF2', '#363457'), color: colorTheme('#687692', '#B6C6E7') }}
                />
            ) : null}
            {isLoggedIn && isNeedApprove ? (
                <Flex pr={is2XL ? '8px' : '0'} justifyContent="end" w="251px" gridRow="span 2" justifySelf="end">
                    <ApprovalForAllButton onSuccess={refreshPosition} miningContract={entry.meta.miningContract} />
                </Flex>
            ) : null}
        </Stack>
    ) : (
        <HStack pb={{ base: '0px', sm: '20px', xxl: '0px' }}>
            {/* <RewardOrPriceRange
                data={entry.data}
                meta={entry.meta}
                w={oneLineMode ? '140px' : '196px'}
                label={t('Reward Range')}
                handleToggle={() => {
                    dispatch.farmFixRangeiZi.togglePoolMetaInitialToggle(entry.meta.liquidityPoolKey);
                }}
            /> */}

            <Rewards
                oneLineMode={oneLineMode}
                rewards={
                    entry.data.reward?.map((e: any) => [
                        e.token as unknown as TokenInfoFormatted,
                        entry.meta.useTimestamp ? (e.rewardDecimalPerSecond as number) : (e.rewardDecimalPerBlock as number),
                    ]) || []
                }
                useTimestamp={entry.meta.useTimestamp}
            />
            {console.info('entry ', entry)}
            {isLoggedIn ? (
                <Earned
                    gridRow="span 2"
                    justifySelf="end"
                    entry={entry}
                    onHarvest={() => {
                        if (miningContract && account) {
                            estimateCollectRewardsGas(miningContract, account).then((gas: number) => {
                                const chain = getChain(chainId);
                                const toastLink = {} as ToastLink;
                                const gasLimit = new BigNumber(gas * 1.1).toFixed(0, 2);
                                collectRewards(chainId as ChainId, miningContract, account, gasLimit, gasPrice)
                                    .on('transactionHash', (hash: string) => {
                                        if (chain) {
                                            toastLink.title = 'View on ' + chain.name;
                                            toastLink.link = getTxLink(hash, chain);
                                        }
                                        toast('info', 'Collecting all rewards ...', undefined, toastLink);
                                    })
                                    .then(() => {
                                        toast('success', 'Collect all rewards successfully.', undefined, toastLink);
                                        console.log('collectRewards success');
                                        refreshPosition?.();
                                    });
                            });
                        }
                    }}
                    earned={
                        entry.userData.earned &&
                        entry.userData.earned.map((item) => {
                            return parseFloat(item);
                        })
                    }
                />
            ) : null}
            {isLoggedIn && isNeedApprove ? (
                <Flex pr={is2XL ? '8px' : '0'} justifyContent="end" w="251px" gridRow="span 2" justifySelf="end">
                    <ApprovalForAllButton onSuccess={refreshPosition} miningContract={entry.meta.miningContract} />
                </Flex>
            ) : null}
        </HStack>
    );

    const DetailsButton = (
        <>
            {!isMobile && <Spacer />}
            <AccordionButton
                color="secondary.500"
                w="unset"
                className={i_text_copy}
                fontSize="12px !important"
                ml={{ base: '10px !important', sm: 'unset' }}
                px="4px"
            >
                {!is2XL && !isMobile && <Box>{t('Details')}</Box>}
                <AccordionIcon />
            </AccordionButton>
        </>
    );

    const Tags = (
        <VStack alignItems="start">
            {entry.meta.iZiBoost &&
                (isMobile ? (
                    <CustomTag
                        icon={colorTheme('/assets/farm/lightBoost.svg', '/assets/farm/darkBoost.svg')}
                        text="iZi Boost"
                        variant="purple"
                        bg={colorTheme('secondary.50', '#581577')}
                        px="18px"
                    />
                ) : (
                    <CustomTag
                        icon={colorTheme('/assets/farm/lightBoost.svg', '/assets/farm/darkBoost.svg')}
                        text="iZi Boost"
                        variant="purple"
                    />
                ))}
            {entry.meta.veiZiBoost && (
                <CustomTag
                    icon={colorTheme('/assets/farm/lightBoost.svg', '/assets/farm/darkBoost.svg')}
                    text="veiZi Boost"
                    variant="purple"
                />
            )}
            {entry.meta.noFee && (
                <CustomTag
                    icon={colorTheme('/assets/farm/lightFree.svg', '/assets/farm/darkFree.svg')}
                    text="No Platform Fee"
                    variant="green"
                />
            )}
            {entry.meta.twoRewards && (
                <CustomTag
                    icon={colorTheme('/assets/farm/lightDualRewards.svg', '/assets/farm/darkDualRewards.svg')}
                    text="Dual Rewards"
                    variant="yellow"
                />
            )}
        </VStack>
    );
    const Summary = isMobile ? (
        <Stack pt="21px" spacing="20px" minW={{ base: '100%', sm: '660px' }} bg={colorTheme('#FFFFFF', '#25203B')}>
            <HStack spacing={{ base: '18px', xlp1: '28px' }} flexShrink={0} px="30px">
                <TokenIcons tokenA={entry.meta.tokenA} tokenB={entry.meta.tokenB} initialToggle={entry.meta.initialToggle} />
                <FeeRate
                    tokenA={entry.meta.tokenA}
                    tokenB={entry.meta.tokenB}
                    feeTier={entry.meta.feeTier}
                    initialToggle={entry.meta.initialToggle}
                />
                <Image
                    boxSize="30px"
                    src={process.env.PUBLIC_URL + '/assets/sidebar/logo.svg'}
                    fallbackSrc={process.env.PUBLIC_URL + '/assets/sidebar/logo.svg'}
                    ml="auto !important"
                ></Image>

                {isMobile && shouldExpand && DetailsButton}
            </HStack>

            <Stack
                bg={colorTheme('linear-gradient(180deg, #F1F1F1 0%, #FFFFFF 100%)', 'linear-gradient(180deg, #2E274A 0%, #2A2743 100%)')}
                mt="13px !important"
                pb="16px"
            >
                <HStack w="100%" mt={{ base: '11px !important', sm: 'unset !important' }} spacing="28px" px="30px">
                    <APR
                        w={{ base: '140px', sm: '165px' }}
                        apr={entry.data.apr}
                        meta={entry.meta}
                        variant={entry.meta.iZiBoost || entry.meta.veiZiBoost ? 'range' : 'base'}
                        type="iZiSwap"
                    />
                    {/* <Liquidity liquidity={entry.data.vLiquidity} /> */}
                    <TVL tvl={entry.data.tvl} type="iZiSwap" />
                </HStack>
                <Rewards
                    oneLineMode={oneLineMode}
                    rewards={
                        entry.data.reward?.map((e: any) => [
                            e.token as unknown as TokenInfoFormatted,
                            e.rewardDecimalPerBlock as unknown as number,
                        ]) || []
                    }
                    type="mobile"
                />
            </Stack>

            {is2XL && additionalInfo}
            {is2XL && !isLoggedIn && Tags}
            {!isMobile && shouldExpand && DetailsButton}
        </Stack>
    ) : (
        <Stack
            direction={{ base: 'column', sm: 'row' }}
            py="24px"
            pl={{ base: '30px', xlp1: '40px' }}
            pr="12.5px"
            spacing="0"
            minW={{ base: '100%', sm: '660px' }}
        >
            <HStack spacing={{ base: '18px', xlp1: '28px' }} flexShrink={0}>
                <TokenIcons tokenA={entry.meta.tokenA} tokenB={entry.meta.tokenB} initialToggle={entry.meta.initialToggle} />
                <Text w="153px" className={i_text_copy_bold} fontSize="14px">
                    {!entry.meta.initialToggle
                        ? entry.meta.tokenA?.symbol + '/' + entry.meta.tokenB?.symbol
                        : entry.meta.tokenB?.symbol + '/' + entry.meta.tokenA?.symbol}
                    {' LP'}
                </Text>

                {isMobile && shouldExpand && DetailsButton}
            </HStack>

            <HStack mt={{ base: '15px !important', sm: 'unset !important' }} spacing={{ base: '28px', sm: '0px' }}>
                <APR
                    w={{ base: '140px', sm: '165px' }}
                    apr={entry.data.apr}
                    meta={entry.meta}
                    variant={entry.meta.iZiBoost || entry.meta.veiZiBoost ? 'range' : 'base'}
                    type="iZiSwap"
                />
                {/* <Liquidity liquidity={entry.data.vLiquidity} /> */}
                <TVL tvl={entry.data.tvl} type="iZiSwap" />
            </HStack>
            {is2XL && additionalInfo}
            {is2XL && !isLoggedIn && Tags}
            {!isMobile && shouldExpand && DetailsButton}
        </Stack>
    );

    const Details = isMobile ? (
        <AccordionPanel p={{ base: '0px 20px 28px 20px', sm: '12px 30px 28px 40px' }}>
            {isNot2XL && <HStack>{additionalInfo}</HStack>}
            {isLoggedIn && (
                <>
                    <Divider mt="5px" mb="12px" />
                    <PositionList
                        refreshPosition={refreshPosition}
                        refreshPoolListDataAndPosition={refreshPoolListDataAndPosition}
                        type="mobile"
                    />
                </>
            )}
        </AccordionPanel>
    ) : (
        <AccordionPanel p={{ base: '12px 20px 28px 20px', sm: '0px 30px 28px 40px' }}>
            {isNot2XL && (
                <Grid
                    autoFlow="column"
                    templateRows="1fr 1fr"
                    templateColumns={{ base: 'unset', sm: 'max-content auto' }}
                    gridTemplateRows={{ base: 'repeat(4,1fr)', sm: 'unset' }}
                >
                    {additionalInfo}
                </Grid>
            )}
            {isLoggedIn && (
                <>
                    <Divider mb={{ base: '10px', sm: '10px' }} />
                    <PositionList refreshPosition={refreshPosition} refreshPoolListDataAndPosition={refreshPoolListDataAndPosition} />
                </>
            )}
        </AccordionPanel>
    );

    return (
        <Card
            {...rest}
            position="relative"
            border={isFarming || readyToFarm ? '1px' : '0'}
            borderColor={isFarming ? 'primary.300' : 'secondary.300'}
            bg={isMobile && colorTheme('#FFFFFF', 'linear-gradient(180deg, #2E274A 0%, #2A2743 100%)')}
        >
            <CardTagContainer>
                {isFarming && <CardTag variant="blue" text={t('$ Farming')} />}
                {readyToFarm && !isFarming && <CardTag variant="purple" text={t('Ready To Farm')} />}
                {account && entry.meta.iZiBoost && <CardTag variant="purple" text={t('iZi Boost')} />}
                {account && entry.meta.veiZiBoost && <CardTag variant="purple" text={t('veiZi Boost')} />}
            </CardTagContainer>

            <AccordionItem border="0">
                {Summary}
                {shouldExpand && Details}
            </AccordionItem>
        </Card>
    );
});

const Wrapper = chakra(({ entry, ...rest }: { entry: PoolEntryState } & FarmRefreshHandle) => {
    return (
        <PoolEntryProvider value={entry}>
            <PoolLiquidityEntry {...rest} />
        </PoolEntryProvider>
    );
});

export default Wrapper;
