export const announceConfig =
    process.env.REACT_APP_ENV === 'production'
        ? [
              {
                  text: 'Use at your own risk. Click for tutorial.',
                  icon: '',
                  type: 'link',
                  linkText: 'Check more.',
                  onClick: () => {
                      window.open('https://bellafi.gitbook.io/bella-protocol/lp-farm/tutorial');
                  },
                  index: 0,
              },
          ]
        : [
              {
                  text: 'Testnet only, last update time: 2023.04.10',
                  icon: '',
                  type: 'link',
                  linkText: 'Check more.',
                  onClick: null,
                  index: 0,
              },
          ];
